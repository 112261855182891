













































import Base from '@/mixins/Base.vue';
import { IObjectCategory, IObjectCategoryResponse } from '@/interfaces/objectcategory';

const component = Base.extend({
    data() {
        return {
            active: false,

            confirmation: '',
            category: undefined as any | IObjectCategory,
        };
    },
    computed: {
        validated() : boolean {
            return this.confirmation === this.category.name;
        },
    },
    mounted() {
        this.active = true;
        this.getCategory();
    },
    methods: {
        getCategory(): void {
            this.get<IObjectCategoryResponse>(`object-categories/${this.$route.params.id}`).then(({ data }) => {
                this.category = data.data;
            });
        },
        save(): void {
            if (this.validated) {
                this.delete(`categories/${this.category.id}`).then(() => {
                    this.$router.push({ name: 'categories.list' });
                    this.$emit('reload');
                });
            }
        },
    },
});

export default component;
